import React, { useState, useContext } from 'react';

import SiteSettingsContext from './SiteSettingsContext';
import GatsbyIpfFileContext from './GatsbyIpfFileContext';
import { DATA_SOURCE_FUTURES, DATA_SOURCE_INDEX } from '../utils/constants';
import dayjs from '../utils/dayjs';

const defaultState = {
  selectedMonth: {
    month: null,
    symbol: null,
    contractExp: null,
    optionSymbols: []
  },
  monthOptions: [],
  setSelectedMonth: () => {}
};
const MonthContext = React.createContext(defaultState);
MonthContext.displayName = 'MonthContext';

function MonthProvider({ symbol, children }) {
  const siteSettings = useContext(SiteSettingsContext);
  const productData = useContext(GatsbyIpfFileContext);

  // Determine whether we show index data or futures data based on global site setting value.
  const dataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].price_movement_widget_data_source) ||
    DATA_SOURCE_INDEX;

  let monthOptions = null;

  if (dataSource === DATA_SOURCE_FUTURES) {
    const frontMonthSymbol = productData[symbol].frontMonthSymbol;
    const frontMonthExpiration = productData[symbol].frontMonthExpirationMonth;
    const frontMonthExpirationDate =
      productData[symbol].frontMonthExpirationDate;
    const frontMonthLastTradeDate = productData[symbol].frontMonthLastTradeDate;
    // This filtering logic is only in place to get this code into prod before options are ready. See the gatsby-node.js file in the gatsby-source-ipf-file plugin for more details.
    const frontMonthOptionSymbols = productData[
      symbol
    ].frontMonthOptions.filter((symbol) => symbol.length > 0);

    const backMonthSymbol = productData[symbol].backMonthSymbol;
    const backMonthExpiration = productData[symbol].backMonthExpirationMonth;
    const backMonthExpirationDate = productData[symbol].backmonthExpirationDate;
    // When winding down old products, there will be a period of time where there is no back month data, so we need to null check here
    const backMonthOptions = productData[symbol].backMonthOptions || [];
    const backMonthOptionSymbols = backMonthOptions.filter(
      (symbol) => symbol.length > 0
    );

    monthOptions = [
      {
        month: frontMonthExpiration,
        lastTradeDate: frontMonthLastTradeDate,
        symbol: frontMonthSymbol,
        contractExp: 'front',
        optionSymbols: frontMonthOptionSymbols,
        expiration: frontMonthExpirationDate
      }
    ];

    // This logic also addresses the wind down scenario described above
    if (backMonthSymbol) {
      monthOptions.push({
        month: backMonthExpiration,
        lastTradeDate: '',
        symbol: backMonthSymbol,
        contractExp: 'back',
        optionSymbols: backMonthOptionSymbols,
        expiration: backMonthExpirationDate
      });
    }
  }

  const todayIsAfterFrontMonthLastTradeDate = monthOptions
    ? dayjs(monthOptions[0].lastTradeDate, 'YYYY-MM-DD').isBefore(
        dayjs()
          .tz()
          .startOf('day')
      )
    : false;

  const [selectedOption, setSelectedOption] = useState(
    monthOptions
      ? todayIsAfterFrontMonthLastTradeDate
        ? monthOptions[1]
        : monthOptions[0]
      : null
  );

  return (
    <MonthContext.Provider
      value={{
        selectedMonth: selectedOption,
        setSelectedMonth: setSelectedOption,
        monthOptions: monthOptions,
        todayIsAfterFrontMonthLastTradeDate: todayIsAfterFrontMonthLastTradeDate
      }}
    >
      {children}
    </MonthContext.Provider>
  );
}

export { MonthProvider };

export default MonthContext;
